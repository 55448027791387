<template>
    <div class="wrapper_main footer-fixed">
        <main class="content">
            <div class="content__header none-border">
                <div class="content__header-left">
                    <div class="headline">
                        <h1 class="headline__title">{{ $translate('add-herd') }}</h1>
                        <p class="headline__text">{{ $translate('set-up-paddock-on-map-and-write-down-basic-info') }}</p>
                    </div>
                </div>
                <div class="content__header-right">
                    <div class="buttons-group margin-btn flex-auto">
                        <button class="btn btn-w-shadow btn-standart-2">
                            <inline-svg :src="require(`@/assets/img/add-point-icon.svg`)"/>
                            <span class="blue-sky">{{ $translate('add-point') }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="tabs-wrapper">
                <ul class="tabs">        
                    <li class="tabs__item"> 
                        <a data-tab="map" class="nav-tab"  @click="switchTab('map')" :class="isActiveTab('map')"> {{ $translate('map') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="basic-info" class="nav-tab" @click="switchTab('basic-info')" :class="isActiveTab('basic-info')"> {{ $translate('basic-info') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="assign-devices" class="nav-tab" @click="switchTab('assign-devices')" :class="isActiveTab('assign-devices')"> {{ $translate('assign-devices') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="assign-paddock-building" class="nav-tab" @click="switchTab('assign-paddock-building')" :class="isActiveTab('assign-paddock-building')"> {{ $translate('assign-paddock-/-building') }} </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div id="map" class="tab-content__item" :class="isActiveTab('map')">
                    <div class="addition">
                        <div class="map search-map" style="border-radius: var(--border-radius-7); overflow: hidden">
                            <img src="img/farm-map-map3.jpg" style="max-width: 100%; width: 100%" />
                            <input type="text" class="input bg-left search-icon" placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div id="basic-info" class="tab-content__item" :class="isActiveTab('basic-info')">
                    <div class="addition">
                        <div class="wizard-item">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('name-of-herd') }}</label>
                                        <input type="text" class="input" placeholder="Herd Name 1" v-model="basicInfo.herdName" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('type-of-herd') }}</label>
                                        <Dropdown
                                            :options="[
                                                'Placeholder 1',
                                                'Placeholder 2',
                                                'Placeholder 3',

                                            ]"
                                            :current="basicInfo.herdType"
                                            name="herdType"
                                            @change="updateBasicInfo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('age-class') }}</label>
                                        <Dropdown
                                            :options="[
                                                'Placeholder 1',
                                                'Placeholder 2',
                                                'Placeholder 3',

                                            ]"
                                            :current="basicInfo.ageClass"
                                            name="ageClass"
                                            @change="updateBasicInfo"
                                        />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('breed') }}</label>
                                        <Dropdown
                                            :options="[
                                                'Placeholder 1',
                                                'Placeholder 2',
                                                'Placeholder 3',

                                            ]"
                                            :current="basicInfo.breed"
                                            name="breed"
                                            @change="updateBasicInfo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('date-of-birth') }}</label>
                                        <input type="text" class="input" v-model="basicInfo.dateOfBirth"/>
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('origin') }}</label>
                                        <input type="text" class="input" v-model="basicInfo.origin" />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('rfid-number') }}</label>
                                        <input type="text" class="input" v-model="basicInfo.RFIDNumber" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('number-of-animals-in-the-herd') }}</label>
                                        <input type="text" class="input" v-model="basicInfo.numberOfAnimals"/>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-12">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('description') }}*</label>
                                        <textarea class="textarea h-140" :placeholder="$translate('write-product-description-here...')"  v-model="basicInfo.description"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="assign-devices" class="tab-content__item" :class="isActiveTab('assign-devices')">
                    <div class="addition">
                        <div class="content__header none-border">
                            <div class="content__header-left">
                                <div class="headline headline--sm">
                                    <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-paddock') }}</p>
                                </div>
                            </div>
                            <div class="content__header-right">
                                <button type="button" class="btn btn-w-shadow btn-standart-2">
                                    <inline-svg :src="require(`@/assets/img/add-iot-icon.svg`)" />
                                    <span>{{ $translate('add-devices') }}</span>
                                </button>
                            </div>
                        </div>

                        <ul class="editable-list">
                            <li class="editable-list__item">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">Thermometer Name 1</li>
                                        <li class="data-row__item">
                                            <span class="data-row__icon"><inline-svg :src="require(`@/assets/img/Temperature.svg`)" /></span> Temperature
                                        </li>
                                        <li class="data-row__item">Italy, Rome</li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button type="button" class="btn btn-icon red-color">
                                            <inline-svg :src="require(`@/assets/img/ico-remove.svg`)" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button type="button" class="btn btn-w-shadow btn-standart-2">
                            <inline-svg :src="require(`@/assets/img/add-iot-icon.svg`)" />
                            <span>{{ $translate('add-devices') }}</span>
                        </button>
                    </div>
                </div>
                <div class="tab-content__item" id="assign-paddock-building" :class="isActiveTab('assign-paddock-building')">
                    <div class="addition">
                        <div class="flex content-flex-heading">
                            <div class="headline headline--sm">
                                <h2 class="headline__title">{{ $translate('assign-paddock-/-building') }}</h2>
                                <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-paddock' )}}</p>
                            </div>
                        </div>

                        <ul class="editable-list">
                            <li class="editable-list__item">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">Paddock Name 1</li>
                                        <li class="data-row__item">0.1 m²</li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button type="button" class="btn btn-icon red-color">
                                            <inline-svg :src="require(`@/assets/img/ico-remove.svg`)" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button type="button" class="btn btn-w-shadow btn-standart-2">
                            <span>{{ $translate('change-paddock-/-building') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </main>
        <FooterTabsControl
            cancelRoute="farm-map"
            :confirmName="$translate('add-herd')"
            @confirm="confirm"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
        >
        </FooterTabsControl>
        <!-- before translate confirmName="Add Herd" -->
    </div>
</template>

<script>
import Base from "@/components/base";
import { mixTabs } from '@/mixins';
export default {
    name: "AddHerd",
    components: {
        ...Base,
    },
    mixins: [mixTabs],
    data() {
        return {
            basicInfo: {
                herdName: null,
                herdType: null,
                ageClass: null,
                breed: null,
                dateOfBirth: null,
                origin: null,
                RFIDNumber: null,
                numberOfAnimals:null,
            },
            pageTabs:['map', 'basic-info', 'assign-devices', 'assign-paddock-building']
        };
    },
    computed: {
       
    },
    methods: {
        confirm(){
            console.log("confirm")
        },
        updateBasicInfo(ev) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;
            this.basicInfo[name] = value;
        },
        pushRoute(page) {
            this.$router.push({ path: page });
        },
    },
};
</script>
